import React, { Component } from "react";
import I18n from "./I18n";
import ZoneMessages from "./ZoneMessages";
import Layout from './Layout';

export default class Confirmation extends Component {
    constructor(props) {
        super(props);


        //var lng = window.sessionStorage.getItem('language');

        //I18n.changeLanguage(lng);
      
    }

    showResult() {

        if (this.props.objetConfirmation.erreur != null ) {
            return <ZoneMessages id="erreurmsg" typeMessage="erreurTechnique" message={I18n.t("zoneMessages:message" + this.props.objetConfirmation.erreur)} />
        }

        return (<React.Fragment>
            <div id="zoneResume">
                <ZoneMessages id="succesmsg" message={I18n.t("zoneMessages:messageSucces")} />
                <div id="zoneNoConfirmation">
                    <p id="noConfirmation">{I18n.t("confirmation:noConfirmation")}</p>
                    <p data-testid="noDemandeConfirmation" id="noDemandeConfirmation"><b># {this.props.objetConfirmation.id}</b></p>
                    <p id="consigneComfirmation">{I18n.t("confirmation:consigne")}</p>
                </div>
                <div id="zoneInformationComfirmation" role="group" aria-labelledby="prochaineEtape">
                    <h2 id="prochaineEtape">{I18n.t("confirmation:titreProchaineEtape")}</h2>
                    <p id="descriptionProchaineEtape">{I18n.t("confirmation:descriptionProchaineEtape")}</p>
                </div>
            </div>
        </React.Fragment>);

    }

    render() {

        return (
            <div id="confirmation">
                
                <h1 data-testid="titreInfo" id="titreInfo">{I18n.t('confirmation:titre')}</h1>
                    {this.showResult()}
               
            </div>
        );
    }
}