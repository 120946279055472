import React, { Component } from "react";
import axios from 'axios';
import I18n from "./I18n";
import Loading from "./Loading";
import FormulaireCodePaiement from './FormulaireCodePaiement';

export default class SaisieCodePaiement extends Component {
    constructor(props) {
        super(props);

        this.state = { enChargement: false, erreur: null };

        this.obtenirGuidDemande = this.obtenirGuidDemande.bind(this);
    }

    async componentDidMount() {
        window.$("#titreInfo").focus();
    }

    //appel DemandeSaisieCC.GetDemandesParCourrielAuPayeur
    async obtenirGuidDemande(noDemande) {
        this.setState({ enChargement: true });

        await axios.get(`api/v2/ObtenirGUID/${noDemande}`)
            .then(response => this.handleObtenirGuidDemandeResponse(response))
            .catch(error => this.handleFailure(error));
    }

    handleObtenirGuidDemandeResponse(response) {
        this.setState({ enChargement: false });
        this.props.obtenirGuidSucces && this.props.obtenirGuidSucces(response.data.guidDemandeSaisieCC);
    }

    handleFailure(error) {

        this.setState({ enChargement: false });
        switch (error.response.status) {
            case 400:
                this.setState({ erreur: new Error(I18n.t("zoneMessages:messagePEC0045")) });
                break;
            case 404:
                this.setState({ erreur: new Error(I18n.t("zoneMessages:messagePEC0045")) });
                break;
            default:
                this.setState({ erreur: new Error(I18n.t("zoneMessages:messagePEC0053")) });
                break;
        }

        if(this.props.callback)
            this.props.callback();
    }

    render() {
        const { erreur, enChargement } = this.state;

        return (<div id="row" className="row">
            <div id="contenu-demande-paiement" className="col-sm-24 col-xs-24">
                <div id="titreTransaction" className="titreTransactionAvecAncre">
                    <h1 data-testid="titreInfo" id="titreInfo">{I18n.t('saisieCodePaiement:titre')}</h1>
                    <Loading estActif={enChargement} />
                    <FormulaireCodePaiement obtenirGuid={this.obtenirGuidDemande} erreur={erreur} />
                </div>
            </div>
        </div>);
    }
}