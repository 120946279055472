import React, { Component, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Saisie from './components/Saisie';
import Confirmation from './components/Confirmation';
import "n3-ux/dist/fwd/d2/css/bootstrap4desj.min.css";
import "n3-ux/dist/fwd/d2/css/d2.min.css";
import "n3-ux/dist/fwd/d2/css/bootstrap4desj-grid.min.css";

window.jQuery = require("jquery");
window.$ = window.jQuery;

require("n3-ux/dist/vendors/jquery/3.5.1/jquery-3.5.1.slim.min");
require("n3-ux/dist/fwd/d2/js/bootstrap.bundle.min");
require("n3-ux/dist/fwd/d2/js/fwd.bundle.min");

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

function App() {
        return (
            <BrowserRouter basename={baseUrl}>
                <Suspense fallback={<div />}>
                    <Routes>
                        <Route path='/' element={<Saisie />}/>
                    </Routes>
                </Suspense>
            </BrowserRouter>
        );
}

export default App;
