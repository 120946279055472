import React, { Component } from "react";
import I18n from "./I18n";

export default class Loading extends Component {
    constructor(props) {
        super(props);
    }


    render() {
        if (this.props.estActif) {
            return (<div>
                <div data-testid="myLoader" id="myLoader" className="modal loader" aria-live="polite" data-toggle="modal" data-backdrop="static" role="status">
                    <div className="modal-dialog" role="document">
                        <span className="sr-only" id="modalLoaderLabel" role="alert"> {I18n.t('loading:telechargementEnCours')}</span>
                        <div className="modal-content">
                            <div className="chargement" id="chargement"></div>
                        </div>
                    </div>
                </div>
                <div className="isolation-bootstrap-3">
                    <div className="modal-backdrop in">
                    </div>
                </div>
            </div>);
        }
    }
}