import React from 'react';
import { createRoot } from 'react-dom/client';
import axios from 'axios';
import App from './App';

const rootElement = document.getElementById('root');
const rootContainer = createRoot(rootElement);

axios.defaults.headers.common['Access-Control-Allow-Origin'] = "*";

rootContainer.render(
    <App />);


