import React, { Component } from "react";
import axios from 'axios';
import SaisieCodePaiement from './SaisieCodePaiement';
import SaisieCorroboration from './SaisieCorroboration';
import Layout from './Layout';
import Confirmation from "./Confirmation";

export default class Saisie extends Component {
    constructor(props) {
        super(props);

        this.state = { guidDemande: '', objetConfirmation: null };

        this.saisieCodeSucces = this.saisieCodeSucces.bind(this);

        axios.defaults.headers.common['Access-Control-Allow-Origin'] = "*";
        if(props.guidDemande){
            this.state = { guidDemande: props.guidDemande, objetConfirmation: null };
        }
    }

    saisieCodeSucces(guid) {
        this.setState({ guidDemande: guid });
    }

    gererObjetConfirmation = (objetConfirmationRecu) => {
        this.setState({ objetConfirmation: objetConfirmationRecu });
    };

    render() {
        const guid = this.state.guidDemande;
        const objetConfirmation = this.state.objetConfirmation;

        return (<div>
                <Layout afficherChangementLangue={guid === ""}>
                    {
                        guid === "" && objetConfirmation == null &&
                            <SaisieCodePaiement obtenirGuidSucces={this.saisieCodeSucces} />
                    }
                    {
                        guid !== ""  && objetConfirmation == null &&
                            <SaisieCorroboration guid={guid} gererObjetConfirmation={this.gererObjetConfirmation} />
                    }
                    {
                        objetConfirmation != null &&
                            <Confirmation objetConfirmation={objetConfirmation}/>
                    }
                </Layout>
        </div>);
    }
}