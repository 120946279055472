import i18n from "i18next";

const I18n = i18n.init({
    preload: ['fr', 'en'],
    resources: {
        en: {
            loading: {
                "telechargementEnCours": "Loading in progress.",
            },
            layout: {
                "titre": "",
                "contenuPrincipal": "Go to the main content",
                "navigateurDesuet": "This site is not compatible with your web browser.",
                "navigateurFonctionnalites": "Some features of the site are not available or will not work correctly.",
                "lienNavigateur": "https://www.desjardinslifeinsurance.com/en/legal-notice/security/for-a-secure-website-experience",
                "indicationNavigateur": "See the procedure to update your browser.",
                "urlLogo": "https://www.desjardinslifeinsurance.com/en/",
                "dsfLogo": "Desjardins insurance, life, health, retirement (home page).",
                "dsfCheminImage": "./en/logo-dsf-en-US.svg",
                "langageTitre": "Changer votre langue",
                "langue": "Français",
                "langueShort": "fr",
                "sectionSecurisee": "Secured section",
                "securiteUrl": "https://www.desjardinslifeinsurance.com/en/legal-notice/security",
                "securite": "Security",
                "liensExternes": " - External link. This link will open in a new window.",
                "lienConfidentialite": "https://www.desjardinslifeinsurance.com/en/legal-notice/privacy-policy",
                "confidentialite": "Privacy",
                "lienJuridique": "https://www.desjardinslifeinsurance.com/en/legal-notice",
                "juridique": "Terms of use and legal notes",
                "lienPlaintes": "https://www.desjardinslifeinsurance.com/en/about-Desjardins-Insurance/complaints",
                "plaintes": "Complaints",
                "lienAccessibilite": "https://www.desjardinslifeinsurance.com/en/about-Desjardins-Insurance/our-commitment-to-society/accessibility-for-persons-with-disabilities",
                "accessibilite": "Accessibility",
                "preferencesCookies": "Personalize cookies",
                "copyright": "© ",
                "copyright1": " Desjardins Financial Security. All Rights Reserved.",
                "copyright2": "Desjardins Insurance refers to Desjardins Financial Security Life Assurance Company.",
                "pathLogoSecu": "logo-securite-blanc-e.svg",
                "altLogoSecu": "Guaranteed 100% Secure",
            },
            saisieCodePaiement: {
                "titre": "Payment code",
                "aide": "Need help?",
                "aideAppel": "Call us",
                "aideTitre": "This link will launch your default phone software.",
                "heuresOuverture": "(Monday to Friday, 8:00 a.m. to 5:00 p.m. ET)",
                "titreImageAide": "Open help window – How do I find my code?",
                "codePaiement": "Enter your code",
                "codePaiementExemple": "Expected format: A1B2 C3D4E F6G7",
                "soumettre": "Submit",
                "titreAide":"How do I find my code?",
                "texteImageAide": "<div id=\"texteAide\"> \
                                    <p>Your code is made up of 13 alphanumeric characters (format: A1B2 C3D4E F6G7).</p> \
                                    <p>You’ll find it in the email from your representative.</p> \
                                    <p><strong>I can’t find my code. What do I do now?</strong></p> \
                                    <p>Contact your representative.</p> \
                                  </div>",
            },
            validerRenseignements: {
                "titre": "Payment information",
                "sousTitre": "Your personal information",
                "introduction": "Please review and confirm this information:",
                "telephone": "Your phone number:",
                "codePostal": "Your postal code:",
                "confirmation": "Is this information correct?",
                "boutonOui": "Yes",
                "boutonNon": "No",
                "messageConseiller": "Please let your representative know so that they can make the necessary corrections.",
                "conseiller": "Your representative",
                "courriel": "Email address",
                "suivant": "Next",
                "SRInformation": "Step 1 of 2 completed: Personal information",
                "SRPaiement": "Step 2 of 2: Payment method",
                "etapesPrecedentes": "Previous Steps",
                "etape": "Step",
                "selectionnee": "Selected",
                "etapesSuivante": "Nexts Steps",
                "accessibilite": "This link will launch your default e-mail software.",
                "imgSectionPaiementEtape1": "Payment section step 1",
                "imgSectionPaiementEtape2": "Payment section step 2",
            },
            confirmation: {
                "titre": "Payment",
                "noConfirmation": "Your confirmation number:",
                "consigne": "Make a note of it. It’s your record of submitting your payment information.",
                "titreProchaineEtape": "What happens next",
                "descriptionProchaineEtape": "Once the insurance application is complete, your representative will ask you to sign it. You may now close this page.",
                "fermer": "Close",
            },
            zoneMessages: {
                "messageSucces": "Your payment information has been submitted.",
                "messagePEC0042": "The <b>Enter your code field</b> is required.",
                "messagePEC0043": "The <b>Enter your code field</b> must follow the format A1B2 C3D4E F6G7.",
                "messagePEC0044": "The code must follow the format A1B2 C3D4E F6G7.",
                "messagePEC0045": "The code you entered is not valid or has expired.",
                "messagePEC0046": "The code has expired. Please contact your representative.",
                "messagePEC0047": "No insurance proposal is associated with this form. Please contact your representative.",
                "messagePEC0048": "The insurance proposal associated with this form is locked. Please contact your representative.",
                "messagePEC0049": "Your payment information has been submitted.",
                "messagePEC0050": "Errors were detected in the following fields:",
                "messagePEC0051": "The field is required.",
                "messagePEC0052": "The <b>confirm information</b> field is required.",
                "messagePEC0053": "The transaction could not be completed due to a technical error. Please enter your information again.",
                "messagePEC0054": "A problem has occurred and has interrupted the processing of your request. Please try again later.",
                "messagePEC0055": "There was an error processing your request, please try again. If the problem persists, please try again later or contact your representative.",
                "messageURLversionNavigateur": "https://www.desjardinslifeinsurance.com/en/legal-notice/security/for-a-secure-website-experience",
                "messageTexteUrl": "See the procedure for updating your browser."
            },
            maCommande: {
                "titre": "Selection summary",
                "produit": "Coverage requested: Insurance of persons ",
                "noProposition": "Application number:",
                "total": "Total: ",
                "colon":":",
                "labelTableau" : "Selection summary table."
            },
        },
        fr: {
            loading: {
                "telechargementEnCours": "Téléchargement en cours.",
            },
            layout: {
                "titre": "Paiement carte de crédit",
                "contenuPrincipal": "Aller au contenu principal",
                "navigateurDesuet": "Votre navigateur Web ne peut afficher ce site correctement.",
                "navigateurFonctionnalites": "Certaines fonctionnalités du site pourraient ne pas s’afficher correctement ou ne pas être disponibles.",
                "lienNavigateur": "https://www.desjardinsassurancevie.com/fr/avis-juridique/securite/naviguez-en-toute-securite",
                "indicationNavigateur": "Voir la marche à suivre pour mettre votre navigateur à jour.",
                "urlLogo": "https://www.desjardinsassurancevie.com/fr/",
                "dsfLogo": "Desjardins assurances, Vie, santé, retraite (page d'accueil).",
                "dsfCheminImage": "./fr/logo-dsf-fr-FR.svg",
                "langageTitre": "Change your language",
                "langue": "English",
                "langueShort": "en",
                "sectionSecurisee": "Section sécurisée",
                "securiteUrl": "https://www.desjardinsassurancevie.com/fr/avis-juridique/securite",
                "securite": "Sécurité",
                "liensExternes": " - Lien externe au site.S'ouvre dans une nouvelle fenêtre.",
                "lienConfidentialite": "https://www.desjardinsassurancevie.com/fr/avis-juridique/politique-de-confidentialite",
                "confidentialite": "Confidentialité",
                "lienJuridique": "https://www.desjardinsassurancevie.com/fr/avis-juridique",
                "juridique": "Conditions d'utilisation et notes légales",
                "lienPlaintes": "https://www.desjardinsassurancevie.com/fr/a-propos-Desjardins-Assurances/plaintes",
                "plaintes": "Plaintes",
                "lienAccessibilite": "https://www.desjardinsassurancevie.com/fr/a-propos-Desjardins-Assurances/notre-engagement-envers-la-societe/accessibilite-pour-les-personnes-handicapees",
                "accessibilite": "Accessibilité",
                "preferencesCookies": "Personnaliser les témoins",
                "copyright": "© ",
                "copyright1": " Desjardins Sécurité financière.Tous droits réservés.",
                "copyright2": "Desjardins Assurances désigne Desjardins Sécurité financière, compagnie d'assurance vie.",
                "pathLogoSecu": "logo-securite-blanc-f.svg",
                "altLogoSecu": "Sécurité garantie à 100 %",
            },
            saisieCodePaiement: {
                "titre": "Code pour le paiement",
                "aide": "Besoin d'aide?",
                "aideAppel": "Appelez-nous",
                "aideTitre": "Ce lien lancera votre logiciel de téléphonie par défaut.",
                "heuresOuverture": "(du lundi au vendredi, entre 8 h et 17 h, heure de l’Est)",
                "titreImageAide": "Ouvrir la boîte d’aide – Comment trouver mon code?",
                "codePaiement": "Saisissez votre code",
                "codePaiementExemple": "Format attendu : A1B2 C3D4E F6G7",
                "soumettre": "Soumettre",
                "titreAide":"Comment trouver mon code&nbsp;?",
                "texteImageAide": "<div id=\"texteAide\"> \
                                    <p>Votre code est composé de 13 caractères alphanumériques (format A1B2 C3D4E F6G7).</p> \
                                    <p>Votre code se trouve dans le courriel envoyé par votre représentant.</p> \
                                    <p><strong>Je ne trouve pas mon code. Que faire&nbsp;?</strong></p> \
                                    <p>Communiquez avec votre représentant.</p> \
                                </div>",
            },
            validerRenseignements: {
                "titre": "Informations de paiement",
                "sousTitre": "Vos renseignements personnels",
                "introduction": "Veuillez valider ces informations :",
                "telephone": "Votre téléphone",
                "codePostal": "Votre code postal",
                "confirmation": "Ces informations sont-elles exactes&nbsp;?",
                "boutonOui": "Oui",
                "boutonNon": "Non",
                "messageConseiller": "Veuillez aviser votre représentant pour qu’il corrige les informations inexactes.",
                "conseiller": "Votre représentant",
                "courriel": "Adresse courriel",
                "suivant": "Suivant",
                "SRInformation": "Étape 1 de 2 en cours: Valider vos renseignements personnels",
                "SRPaiement": "Étape 2 de 2: Procéder au paiement",
                "etapesPrecedentes": "Étapes précédentes",
                "etape": "Étape",
                "selectionnee": "Sélectionnée",
                "etapesSuivante": "Étapes suivantes",
                "accessibilite": "Ce lien lancera votre logiciel de messagerie par défaut.",
                "imgSectionPaiementEtape1": "Paiement section étape 1",
                "imgSectionPaiementEtape2": "Paiement section étape 2",
            },
            confirmation: {
                "titre": "Paiement",
                "noConfirmation": "Votre numéro de confirmation :",
                "consigne": "Conservez-le. Il s’agit de votre preuve de transaction.",
                "titreProchaineEtape": "Prochaine étape",
                "descriptionProchaineEtape": "Lorsque la demande d’assurance sera complétée, votre représentant vous demandera de la signer. Vous pouvez maintenant fermer cette page.",
                "fermer": "Fermer",
            },
            zoneMessages: {
                "messageSucces": "Vos informations de paiement ont été envoyées avec succès.",
                "messagePEC0042": "Le champ <b>Saisissez votre code</b> est obligatoire.",
                "messagePEC0043": "Le champ <b>Saisissez votre code</b> doit respecter le format A1B2 C3D4E F6G7.",
                "messagePEC0044": "Le code doit respecter le format A1B2 C3D4E F6G7.",
                "messagePEC0045": "Le code saisi n'est pas valide ou le délai pour remplir le formulaire de saisie est expiré.",
                "messagePEC0046": "Le délai pour remplir le formulaire de saisie est expiré. Contactez votre représentant.",
                "messagePEC0047": "Aucune proposition d’assurance n’est associée au formulaire de saisie. Contactez votre représentant.",
                "messagePEC0048": "La proposition d’assurance associée au formulaire de saisie est verrouillée. Contactez votre représentant.",
                "messagePEC0049": "Vos informations de paiement ont été envoyées avec succès.",
                "messagePEC0050": "Des erreurs ont été détectées dans les champs suivants :",
                "messagePEC0051": "Le champ est obligatoire",
                "messagePEC0052": "Le champ <b>confirmation des informations</b> est obligatoire.",
                "messagePEC0053": "La transaction n’a pu être effectuée en raison d’une erreur technique. Veuillez saisir vos informations à nouveau.",
                "messagePEC0054": "Un problème est survenu et a causé l'interruption du traitement de votre demande. Veuillez essayer de nouveau plus tard.",
                "messagePEC0055": "Une erreur est survenue lors du traitement de votre demande, veuillez réessayer à nouveau. Si le problème persiste, veuillez réessayer ultérieurement ou contacter votre représentant.",
                "messageURLversionNavigateur": "https://www.desjardinsassurancevie.com/fr/avis-juridique/securite/naviguez-en-toute-securite",
                "messageTexteUrl": "Voir la marche à suivre pour mettre votre navigateur à jour."
            },
            maCommande: {
                "titre": "Résumé de vos choix",
                "produit": "Protection demandée: assurance de personnes",
                "noProposition": "Numéro de proposition:",
                "total": "Total : ",
                "colon": "\u00A0:",
                "labelTableau" : "Tableau: Résumé de vos choix."
            },
        }
    },
    fallbackLng: 'fr',
    debug: false,

    // have a common namespace used around the full app
    ns: ['layout'],
    defaultNS: 'layout',

    keySeparator: false, // we use content as keys

    interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ','
    },

    react: {
        wait: true
    }
});

export default I18n;
