import React, { Component } from "react";
import I18n from "./I18n";

export default class BarreProgression extends Component {
    render() {
        return (
            <div className="etape-bulle" data-initplugin="n3EtapeBulle">
                <div className="etape-bulle-pagination">
                    <ul className="etape-bulle-list">
                        <li className="etape-bulle-item etape-courante">
                            <span className="etape-bulle-cercle">
                                <img data-testid="imgSectionPaiementActif" id="imgSectionPaiementEtape1" alt={I18n.t('validerRenseignements:imgSectionPaiementEtape1')} src={"picto_info-client_actif.svg"} />
                                <span className="sr-only">{I18n.t('validerRenseignements:SRInformation')}</span>
                            </span>
                        </li><li  className="etape-bulle-item ">
                            <span  className="etape-bulle-cercle">
                                <img data-testid="imgSectionPaiementInactif" id="imgSectionPaiementEtape2" alt={I18n.t('validerRenseignements:imgSectionPaiementEtape2')} src={"picto_carte-credit_inactif.svg"} />
                                <span className="sr-only">{I18n.t('validerRenseignements:SRPaiement')}</span>
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}