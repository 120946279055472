/**
 * Représente un état (data behind) de l'objet de confirmation
 */
 class ObjetConfirmation {
    /**
     * Crée un EtatControle
     * @param {string} id - L'id de la confirmation
     * @param {string} erreur - Libellé représentant l'id de l'erreur
     */
    constructor(id, erreur) {
      this.id = id;
      this.erreur = erreur;
    }
  }
  export default ObjetConfirmation;
  