import React, { Component } from 'react';
import I18n from "./I18n";

export default class ZoneMessages extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        switch (this.props.typeMessage) {
            case "erreurValidation":
                var firstPart = this.props.message;
                var secondPart = "";
                var boldText = "";
                if (firstPart.toLowerCase().indexOf("<b>") !== -1) {
                    boldText = this.props.message.substr(this.props.message.toLowerCase().indexOf("<b>") + 3, this.props.message.toLowerCase().indexOf("</b>") - (this.props.message.toLowerCase().indexOf("<b>") + 3));
                    firstPart = this.props.message.substr(0, this.props.message.toLowerCase().indexOf("<b>"));
                    secondPart = this.props.message.substr(this.props.message.toLowerCase().indexOf("</b>") + 4);
                }
                return (<div data-testid="zoneMessagesListe" id="zoneMessages" className="has-error alert alert-danger" role="alert">
                    <div data-testid="boitemessagesListe" id="boitemessages" className="error-group" role="group" aria-labelledby="messageErreur">
                        <span data-testid="messagesErreurListe" id="messageErreur" className="help-block listeErreurs" tabIndex="-1">{I18n.t('zoneMessages:messagePEC0050')}</span>
                        <ul data-testid="champsErreurListe" id="erreurs">
                            <li><a href={"#" + this.props.nomChamp} >{firstPart}<b>{boldText}</b>{secondPart}</a></li>
                        </ul>
                    </div>
                </div>);
            case "erreurTechnique":
                return (<div id="zoneMessages" className="has-error alert alert-danger" role="alert">
                    <span id="messageErreur" className="bloc-erreur-generique" tabIndex="-1">
                        {this.props.message}
                    </span>
                </div>);
            case "avertissement":
                return (<div id="zoneMessages" className="alert alert-warning" role="alert">
                    <span id="messageWarning" tabIndex="-1">
                        {this.props.message}
                    </span>
                </div>);
            default:
                return (<div id="zoneMessages" className="alert alert-success" role="alert">
                    <span id="messageSucces" tabIndex="-1">
                        {this.props.message}
                    </span>
                </div>);
        }
    }
}