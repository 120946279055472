import React, { Component } from "react";
import axios from 'axios';
import I18n from "./I18n";
import Etapes from "./Etapes";
import Loading from "./Loading";
import FormulaireCorroboration from './FormulaireCorroboration';
import BarreProgression from './BarreProgression';
import Panier from './Panier';
import ObjetConfirmation from "../models/ObjetConfirmation";

export default class SaisieCorroboration extends Component {
    constructor(props) {
        super(props);

        this.state = { enChargement: false, informations: null, confirmation: null, erreur: null };

        this.soumettreDemandePaiement = this.soumettreDemandePaiement.bind(this);

        this.erreurEnregistrerInfoPaiement = null;
    }

    async componentDidMount() {
        this.setState({ enChargement: true });

        //appel DemandeSaisieCC.GetDemandeSaisie
        await axios.get(`api/v2/ObtDemandesSaisieCC/${this.props.guid}`, {
            headers: {
                'Accept-Language': this.props.langue,
            }
        })
            .then(response => this.handleObtenirDemandesSaisieCCResponse(response))
            .catch(error => this.handleFailure(error));

        this.setState({ enChargement: false });
    }

    handleObtenirDemandesSaisieCCResponse(response) {
        this.setState({ informations: response.data });
    }

    async soumettreDemandePaiement() {
        this.setState({ enChargement: true });
        this.setState({ erreur: null });

        //affichage récupération des information de configuration et affichage du popup hpp
        await axios.post(`api/v2/ObtenirConfigPaiement/${this.props.guid}`, null, {
            headers: {
                'Accept-Language': this.props.langue,
            }
        })
            .then(response => this.handleInitierDemandeReponse(response))
            .catch(error => this.handleFailure(error));
    }

    handleInitierDemandeReponse(response) {
        if (response.data && response.data !== '' && response.data.initierDemandeReponse && response.data.urlHppGlobalPayment) {
            //On ajoute pas le script si il est déjà chargé
            var scatScriptPCI = document.getElementById("ScatScriptPCI");
            if (!scatScriptPCI) {
                //Route vers ScatScriptPCI
                axios.post(`api/v2/ObtenirJavascriptPaiement/`, {
                    headers: {}
                })
                    .then(responseJS => this.handleObtenirJavascriptPaiement(responseJS, response))
                    .catch(error => this.handleFailure(error));
            } else {
                //afficher le popup de saisie de carte de crédit chez Global Payment
                this.setState({ enChargement: false }, () => { window.afficherPopupRealexHpp(response.data, this.traiterEnregistrementPaiementHpp) });
            }
        } else {
            this.setState({ erreur: new Error(I18n.t("zoneMessages:messagePEC0054")) });
        }
    }

    handleObtenirJavascriptPaiement(responseJS, response) {
        const script = document.createElement("script");
        script.async = true;
        script.innerHTML = responseJS.data;
        script.setAttribute("id", "ScatScriptPCI");
        script.setAttribute("data-testid", "ScatScriptPCI");
        document.body.appendChild(script);
        //afficher le popup de saisie de carte de crédit chez Global Payment
        this.setState({ enChargement: false }, () => {
            window.afficherPopupRealexHpp(response.data, this.traiterEnregistrementPaiementHpp);

            if (document.getElementById('pas_ccnum') != null){
                document.getElementById('pas_ccnum').focus();
            }
        });
    }

    traiterEnregistrementPaiementHpp = (paiement) => {
        this.setState({ enChargement: true });
        this.setState({ erreur: null });

        //la presence d'un message indique une erreur
        if (paiement.message && paiement.message !== '') {
            this.setState({ erreur: new Error(I18n.t("zoneMessages:message" + paiement.message)) });
            this.setState({ enChargement: false });
        }
        else {
            this.EnregistrerInfoPaiement(paiement);
        }
    }

    EnregistrerInfoPaiement(infoPaiement) {
        //affichage récupération des information de configuration et affichage du popup hpp
        axios.post(`api/v2/EnregistrerInfoPaiement/${this.props.guid}`, infoPaiement, {
            headers: {
                'Accept-Language': this.props.langue,
                'noProposition' : this.state.informations.infoCommande.soumission.valeur
            }
        })
            .then(response => this.handleEnregistrerInfoPaiementReponse(response))
            .catch(error => this.handleFailureEnrInfoPaiement(error));
    }

    handleEnregistrerInfoPaiementReponse(response) {
        this.setState({ enChargement: false });
        if (response.data.noConfirmation != null) {
            this.props.gererObjetConfirmation && this.props.gererObjetConfirmation(new ObjetConfirmation(response.data.noConfirmation, this.erreurEnregistrerInfoPaiement));
        }
        else {
            this.erreurEnregistrerInfoPaiement = "PEC0054";
            this.props.gererObjetConfirmation && this.props.gererObjetConfirmation(new ObjetConfirmation("", this.erreurEnregistrerInfoPaiement));
        }
    }

    handleFailureEnrInfoPaiement(error) {

        this.setState({ enChargement: false });

        if (error.response == null) {
            this.erreurEnregistrerInfoPaiement = "PEC0054";
            this.props.gererObjetConfirmation && this.props.gererObjetConfirmation(new ObjetConfirmation("", this.erreurEnregistrerInfoPaiement));
        }
        else {
            switch (error.response.status) {
                case 400:

                    if (error.response.data.errors != null && error.response.data.errors.length > 0) {
                        var msg = error.response.data.errors[0].code;
                        if (msg != null && (msg == "PEC0047" || msg == "PEC0048")) {
                            this.erreurEnregistrerInfoPaiement = msg;
                            this.props.gererObjetConfirmation && this.props.gererObjetConfirmation(new ObjetConfirmation("", this.erreurEnregistrerInfoPaiement));
                            break;
                        }
                        else
                            this.erreurEnregistrerInfoPaiement = "PEC0054";
                            this.props.gererObjetConfirmation && this.props.gererObjetConfirmation(new ObjetConfirmation("", this.erreurEnregistrerInfoPaiement));
                        break;
                    }
                    else {
                        this.erreurEnregistrerInfoPaiement = "PEC0054";
                        this.props.gererObjetConfirmation && this.props.gererObjetConfirmation(new ObjetConfirmation("", this.erreurEnregistrerInfoPaiement));
                        break;
                    }

                default:
                    this.erreurEnregistrerInfoPaiement = "PEC0054";
                    this.props.gererObjetConfirmation && this.props.gererObjetConfirmation(new ObjetConfirmation("", this.erreurEnregistrerInfoPaiement));
                    break;
            }
        }

        if (this.props.callback)
            this.props.callback();
    }

    handleFailure(error) {
        this.setState({ enChargement: false });
        this.setState({ erreur: new Error(I18n.t("zoneMessages:messagePEC0054")) }, () => {
            if (this.props.callback)
                this.props.callback();
        });
    }

    render() {
        const { erreur, enChargement, confirmation, informations } = this.state;

        return (<div id="rowInfo" className="row">
            <div id="titreInformations" className="titreTransactionAvecAncre">
                <h1 id="titreInfo">{I18n.t('validerRenseignements:titre')}</h1>
                <Etapes />
                <Loading estActif={enChargement} />
                <BarreProgression />
                <h2 id="soustitreModePaiement" className="sousTitreModePaiement">{I18n.t('validerRenseignements:sousTitre')}</h2>
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4" id="info">
                        <Panier informations={informations} langue={this.props.langue} />
                    </div>
                    <div data-testid="information-paiement" id="information-paiement" className="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                        <FormulaireCorroboration soumettreDemande={this.soumettreDemandePaiement} informations={informations} erreur={erreur} />
                    </div>
                </div>
            </div>
        </div>);
    }
}