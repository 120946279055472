import React, { Component } from "react";
import I18n from "./I18n";

export default class Etapes extends Component {
    render() {
        return (<div data-testid="etapes" className="etapes">
            <div className="etape-bulle" data-initplugin="n3EtapeBulle">
                <div className="etape-bulle-pagination">
                    <button id="btnPrev" className="etape-bulle-button etape-bulle-button-previous js-previous hide" type="button"><span className="sr-only">{I18n.t("validerRenseignements:etapesPrecedentes")}</span></button>
                    <ul className="etape-bulle-list">
                        <li data-testid="etapebulle" id="step1" className="etape-bulle-item active"><span className="etape-bulle-cercle"><span className="sr-only"> {I18n.t("validerRenseignements:etape")} </span> <span className="etape-bulle-nombre">1</span> <span className="etape-bulle-active sr-only">{I18n.t("validerRenseignements:selectionnee")}</span></span></li>
                        <li data-testid="etapebulle" id="step2" className="etape-bulle-item"><span className="etape-bulle-cercle"><span className="sr-only"> {I18n.t("validerRenseignements:etape")} </span> <span className="etape-bulle-nombre">2</span></span></li>
                    </ul>
                    <button id="btnNext" className="etape-bulle-button etape-bulle-button-next js-next" type="button"><span className="sr-only">{I18n.t("validerRenseignements:etapesSuivante")}</span></button>
                </div>
            </div>
            <h2 data-testid="sousTitreInfo" id="sousTitreInfo">{I18n.t("validerRenseignements:sousTitre")}</h2>
        </div>);
    }
}