import React, { Component } from "react";
import axios from 'axios';
import I18n from "./I18n";

export default class Footer extends Component {
    async componentDidMount() {
        let dataDomainCookies;
        await axios.get('api/v2/ObtenirDataDomainCookies')
            .then((response) => { dataDomainCookies = response.data });

        const scriptCookie = document.createElement('script');
        scriptCookie.setAttribute('type', 'text/javascript');
        scriptCookie.src = "https://static.desjardins.com/fw/cookie/scriptCookie.js";
        scriptCookie.id = "consentementCookiesScript";
        scriptCookie.setAttribute('data-domain-script', dataDomainCookies);

        document.body.insertAdjacentElement("afterbegin", scriptCookie);
    }

    render() {
        return (
            <div id="zonePiedDePage" className="zone-pied-de-page">
                <footer role="contentinfo" id="footerDiv">
                    <div className="zone-pied-de-page">
                        <div className="container">
                            <div id="zoneLegale" className="zone-legale-no-border" nav="true">
                                <nav>
                                    <ul>
                                        <li >
                                            <a id="securiteUrl" target="_blank" className="lien-pied-de-page" href={I18n.t("securiteUrl")}>
                                                {I18n.t("securite")}
                                                <span data-testid="descriptionLienSecurite" className="sr-only">{I18n.t("liensExternes")}</span>
                                            </a>
                                        </li>
                                        <li >
                                            <a id="juridiqueUrl" target="_blank" className="lien-pied-de-page" href={I18n.t("lienJuridique")}>
                                                {I18n.t("juridique")}
                                                <span data-testid="descriptionLienJuridique" className="sr-only">{I18n.t("liensExternes")}</span>
                                            </a>
                                        </li>
                                        <li >
                                            <a id="confidentialiteUrl" target="_blank" className="lien-pied-de-page" href={I18n.t("lienConfidentialite")}>
                                                {I18n.t("confidentialite")}
                                                <span data-testid="descriptionLienConfidentialite" className="sr-only">{I18n.t("liensExternes")}</span>
                                            </a>
                                        </li>
                                        <li >
                                            <a href="#" role="button" className="ot-sdk-show-settings lien-pied-de-page">{I18n.t("preferencesCookies")}</a>
                                        </li>
                                        <li >
                                            <a id="plaintesUrl" target="_blank" className="lien-pied-de-page" href={I18n.t("lienPlaintes")}>
                                                {I18n.t("plaintes")}
                                                <span data-testid="descriptionLienPlaintes" className="sr-only">{I18n.t("liensExternes")}</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a id="accessibiliteUrl" target="_blank" className="lien-pied-de-page" href={I18n.t("lienAccessibilite")}>
                                                {I18n.t("accessibilite")}
                                                <span data-testid="descriptionLienAccessibilite" className="sr-only">{I18n.t("liensExternes")}</span>
                                            </a>
                                        </li>
                                    </ul>
                                    
                                </nav>
                                <p id="copyright" className="copyright">{I18n.t("copyright")}{(new Date().getFullYear())}{I18n.t("copyright1")}<br aria-hidden="true" />{I18n.t("copyright2")}</p>
                                <div className="logo-securite">
                                    <img border="0" height="30" width="114" src={I18n.t('pathLogoSecu')} alt={I18n.t('altLogoSecu')} />
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}