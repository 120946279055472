import React, { Component } from "react";
import I18n from "./I18n";

export default class Panier extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.informations != null && this.props.langue != null) {
            var montant = "";
            if(this.props.langue === "fr")
                montant = this.props.informations.infoCommande.montantCommande.entier.replace(/\B(?=(\d{3})+(?!\d))/g, " ") + "," + this.props.informations.infoCommande.montantCommande.decimale + " $";
            else
                montant = "$" + this.props.informations.infoCommande.montantCommande.entier.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "." + this.props.informations.infoCommande.montantCommande.decimale;
            return (
                <div data-testid="panierResume" id="panierResume" role="presentation">
                        <div className="hidden-xs">
                            <table className="table" role="presentation" aria-label={I18n.t("maCommande:labelTableau")}>
                                <thead>
                                    <tr>
                                    <td className="section-titre-panier-desktop" colSpan="2">
                                        <img className="img-resume-choix-desktop" alt="" src={"picto_resume-choix_c01.svg"} />
                                            <h3 data-testid="titreCommande" id="titreCommande">
                                                {I18n.t("maCommande:titre")}
                                            </h3>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className="ligne-nom-produit" colSpan="2">
                                            <p data-testid="couleur-nom-produit" id="couleur-nom-produit">{this.props.informations.infoCommande.produit}</p>
                                        </td>
                                    </tr>
                                    <tr className="ligne-produit-montant">
                                        <td className="cellule-nom-produit" data-testid="nomproduit" id="nomproduit">{this.props.informations.infoCommande.montantCommande.libelle}{I18n.t("maCommande:colon")}</td>
                                        <td className="cellule-montant-produit" data-testid="montantProduit" id="montantproduit"><span>{montant}</span></td>
                                    </tr>
                                    <tr className="ligne-total">
                                        <td colSpan="2">
                                            <p id="totalpanier">{I18n.t("maCommande:total")}<span data-testid="montantTotalPanier" id="montantTotalPanier">{montant}</span></p>
                                        </td>
                                    </tr>
                                    <tr className="ligne-info-soumission filet-info-soumission">
                                        <td className="cellule-libelle-soumission" data-testid="infoSoumission" id="infoSoumission">{this.props.informations.infoCommande.soumission.libelle}{I18n.t("maCommande:colon")}</td>
                                        <td className="cellule-no-soumission" data-testid="noSoumission" id="nosoumission">{this.props.informations.infoCommande.soumission.valeur}</td>
                                    </tr >
                                </tbody >
                            </table>
                        </div>

                        <div className="hidden-sm hidden-md hidden-lg">
                            <div className="accordion-group allege espacement-bas-panier-achat-mobile" role="tablist">
                                <div className="accordion panel-tiroir filet-tiroir-mobile">
                                    <div className="card">
                                    <div className="card-header" role="tab" id="allege-headingOne">
                                        <img className="img-resume-choix" src={"picto_resume-choix_c01.svg"} />
                                        <h3 className="card-header-title icone-taux">
                                            <button id="titrepanier" className="card-header-button" type="button" data-toggle="collapse" href="#allege-collapseOne" aria-controls="allege-collapseOne" aria-expanded="false">
                                                {I18n.t("maCommande:titre")}
                                            </button>
                                        </h3>
                                    </div>
                                    <div id="allege-collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="allege-headingOne">
                                        <div className="panel-body filet-tiroir-mobile format-tiroir-mobile">
                                            <table className="table" role="presentation">
                                            <tbody>
                                                    <tr className="ligne-nom-produit">
                                                            <td className="filet-haut-panier-achat" colSpan="2">
                                                                <p id="couleur-nom-produit">{this.props.informations.infoCommande.produit}</p>
                                                        </td>
                                                    </tr>
                                                    <tr className="ligne-produit-montant">
                                                        <td className="cellule-nom-produit" id="nomproduit">{this.props.informations.infoCommande.montantCommande.libelle}{I18n.t("maCommande:colon")}</td>
                                                        <td className="cellule-montant-produit" id="montantproduit"><span>{montant}</span></td>
                                                    </tr>
                                                    <tr className="ligne-total">
                                                        <td colSpan="2">
                                                            <p id="totalpanier">{I18n.t("maCommande:total")}<span id="montantTotalPanier">{montant}</span></p>
                                                        </td>
                                                    </tr>
                                                    <tr className="ligne-info-soumission">
                                                        <td className="cellule-libelle-soumission" id="infosoumission">{this.props.informations.infoCommande.soumission.libelle}{I18n.t("maCommande:colon")}</td>
                                                        <td className="cellule-no-soumission" id="nosoumission">{this.props.informations.infoCommande.soumission.valeur}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
            );
        }
        return (<div className="col-sm-8" data-testid="panierVide"></div>);
    }
}