import React, { Component } from "react";
import I18n from "./I18n";
import ZoneMessages from "./ZoneMessages";

export default class FormulaireCorroboration extends Component {
    constructor(props) {
        super(props);
        this.state = { value: '', aValider: false, erreur: null };

        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        if (this.state.erreur === null && this.state.value === '' && this.state.aValider === false) {
            window.$("#titreInfo").focus();
        }
    }

    handleChange(event) {
        this.setState({ value: event.target.value, aValider: false });
        this.validateForm(event.target.value, this.state.aValider);

        if (this.state.value === "1") {
            window.$("#messageErreur").focus();
        }
    }
    handleBlur(event) {
        if (this.state.value === "1") {
            window.$("#messageErreur").focus();
        }
    }

    handleSubmit(event) {
        this.setState({ aValider: true });

        if (this.validateForm(this.state.value, true)) {
            this.props.soumettreDemande && this.props.soumettreDemande(this.state.value);
        }

        event.preventDefault();
    }

    validateForm(valeur, aValider) {
        if (aValider && valeur === "") {
            this.setState({
                erreur: new EvalError("champVide")
            });

            return false;
        }

        this.setState({ erreur: null });
        return true;
    }

    showErrorStyle() {
        var style = "radioInput";
        if (this.state.erreur != null && this.state.erreur instanceof EvalError) {
            style = `${style} radio-has-error `;
        }

        return style;
    }

    showDonneesRepresentant() {
        if (this.state.value === "1") {
            var courriel = "mailto:" + this.props.informations.parametrePageCorroboration.courrielRepresentant;
            return <div id="informationRepresentant">
                <div id="containerAvertissementRepresentant">
                    <ZoneMessages typeMessage="avertissement" message={I18n.t("validerRenseignements:messageConseiller")} />
                </div>
                <p id="nomConseillerLibelle"><b>{I18n.t("validerRenseignements:conseiller")}</b></p>
                <p data-testid="nomConseiller" id="nomConseiller">{this.props.informations.parametrePageCorroboration.nomCompletRepresentant}</p>
                <a data-testid="courrielConseiller" id="courrielConseiller" href={courriel} >{this.props.informations.parametrePageCorroboration.courrielRepresentant}
                    <span className="sr-only">&nbsp;{I18n.t("validerRenseignements:accessibilite")}</span>
                </a>
            </div>;
        }
        else if (this.state.value === "0" && !this.state.aValider) {
            window.$("#informationsExactesOui").focus();
        }

        return null;
    }

    showErrors() {
        if (this.state.erreur != null && this.state.erreur instanceof EvalError) {
            var message = I18n.t("zoneMessages:messagePEC0051");

            return <span data-testid="helpBlockCorroboration" id="helpBlock" className="has-error">{message}</span>;
        }

        return null
    }

    showSummary() {
        if (this.state.erreur != null) {
            var message = I18n.t("zoneMessages:messagePEC0052");

            return <ZoneMessages id="erreurmsg" typeMessage="erreurValidation" nomChamp="informationsExactesOui" message={message} />
        }
        else if (this.props.erreur != null) {
            return <ZoneMessages id="erreurmsg" typeMessage="erreurTechnique" message={this.props.erreur.message} />
        }

        return null;
    }

    showCodePostal() {
        if (this.props.informations.parametrePageCorroboration.codePostalPayeur !== "") {
            return <div><p data-testid="codePostalPayeurLibelle" id="codePostalPayeurLibelle">{I18n.t("validerRenseignements:codePostal")}</p>
                <p data-testid="codePostalPayeur" id="codePostalPayeur"><b>{this.props.informations.parametrePageCorroboration.codePostalPayeur}</b></p>
            </div>
        }

        return null;
    }

    render() {
        if (this.props.informations != null) {
            return <form data-testid="corroboration" id="corroboration" onSubmit={this.handleSubmit}>
                <div data-testid="formulaire-corroboration" id="formulaire" className="form-group form-horizontal">
                    {this.showSummary()}
                    <p data-testid="introduction" id="introduction">{I18n.t("validerRenseignements:introduction")}</p>
                    <br aria-hidden="true" />
                    <p data-testid="noTelephonePayeurLibelle" id="noTelephonePayeurLibelle">{I18n.t("validerRenseignements:telephone")}</p>
                    <p data-testid="noTelephonePayeur" id="noTelephonePayeur"><b>{this.props.informations.parametrePageCorroboration.noTelephonePayeur}</b></p>
                    {this.showCodePostal()}
                    <br aria-hidden="true" />
                    <fieldset role="radiogroup">
                        <legend data-testid="informationsExactes" id="informationsExactes" dangerouslySetInnerHTML={{__html: I18n.t("validerRenseignements:confirmation")}}/>
                        {this.showErrors()}
                        <div id="choixInformationsExactes">
                            <div id="zoneChoixOui" className="custom-control-inline">
                                <label data-testid="labelChoixOui" htmlFor="informationsExactesOui" className="form legend">
                                    <input data-testid="informationsExactesOui" id="informationsExactesOui" name="informationsExactes" className={this.showErrorStyle()} type="radio" value="0" onChange={this.handleChange} onBlur={this.handleBlur} />
                                    {I18n.t("validerRenseignements:boutonOui")}
                                </label>
                            </div>
                            <div id="zoneChoixNon" className="custom-control-inline">
                                <label data-testid="labelChoixNon" htmlFor="informationsExactesNon">
                                    <input data-testid="informationsExactesNon" id="informationsExactesNon" name="informationsExactes" className={this.showErrorStyle()} type="radio" value="1" onChange={this.handleChange} onBlur={this.handleBlur} />
                                    {I18n.t("validerRenseignements:boutonNon")}
                                </label>
                            </div>
                        </div>
                    </fieldset>
                    {this.showDonneesRepresentant()}
                </div>
                {this.state.value !== "1" ? <input id="submit" className="btn btn-primary btn-bas-formulaire" type="submit" value={I18n.t('validerRenseignements:suivant')} /> : null}
            </form>;
        }

        return (<form data-testid="corroboration" id="corroboration" onSubmit={this.handleSubmit}>
            <div data-testid="erreur-corrobation" id="formulaire" className="form-group form-horizontal">
                {this.showErrors()}
            </div>
        </form>);
    }
}