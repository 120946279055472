import React, { Component } from "react";
import Header from "./Header";
import Footer from "./Footer";
import axios from 'axios';
import I18n from "./I18n";

export default class Layout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            langue: '',
            urlFr: null,
            urlEn: null
        };

        this.handleChangementHeaderLangue = this.handleChangementHeaderLangue.bind(this);
    }

    async componentDidMount() {
        await axios.get(`api/v2/GetUrls`)
            .then(response => this.handleGetUrlsResponse(response))
            .catch(error => this.handleFailure(error));

        I18n.changeLanguage(this.state.langue);

        this.props.changementLangue && this.props.changementLangue(this.state.langue);
    }

    handleGetUrlsResponse(response) {
        var ret = JSON.parse(response.data.replace(/'/g, '"'));
        window.sessionStorage.setItem('language', 'fr');
        var codeLangue = 'fr';
        var titleDoc = "Informations de paiement | Desjardins";

        if (window.location.hostname.indexOf(ret.urlEN) + 1) {
            window.sessionStorage.setItem('language', 'en');
            codeLangue = 'en';
            titleDoc = "Payment information | Desjardins";
        }
        document.title = titleDoc;
        document.documentElement.lang = codeLangue;

        this.setState({
            langue: codeLangue,
            urlFr: `${ret.schema}://${ret.urlFR}`,
            urlEn: `${ret.schema}://${ret.urlEN}`
        });

        if(this.props.callback)
            this.props.callback();
    }

    handleFailure(error) {
        this.setState({
            langue: "fr",
            afficherChangementLangue: false
        });

        document.title = "Informations de paiement | Desjardins";
    }

    handleChangementHeaderLangue(lang) {
        this.setState({
            langue: lang
        });
        window.sessionStorage.setItem('language', lang);
    }

    render() {
        const children = React.Children.map(this.props.children, (child) => {
            if (React.isValidElement(child)) {
                return React.cloneElement(child, { langue: this.state.langue });
            }
        });
        if (this.state.langue !== '') {
            return (<div>
                <Header changementLangue={this.handleChangementHeaderLangue} lienChangementLangue={this.state} afficherLienChangementLangue={this.props.afficherChangementLangue} />
                <div id="zoneCentrale" className="zone-centrale">
                    <main>
                        <div id="contenu" className="container">
                            {children}
                        </div>
                    </main>
                </div>
                <Footer />
            </div>);
        }
        return (<div>
        </div>);
    }
}