import React, { Component } from "react";
import { detect } from "detect-browser";
import I18n from "./I18n";

export default class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {

        I18n.changeLanguage(this.props.lienChangementLangue.langue);
        
        this.props.changementLangue && this.props.changementLangue(this.props.lienChangementLangue.langue);
    }

    obtenirUrlChangementLangue() {
        if (this.props.lienChangementLangue.langue === "fr") {
            return this.props.lienChangementLangue.urlEn;
        }

        return this.props.lienChangementLangue.urlFr;
    }

    afficherLienLangue() {
        if (this.props.afficherLienChangementLangue) {
            return (<li id="liensLangue">
                <a id="langue" lang={I18n.t('langueShort')} href={this.obtenirUrlChangementLangue()} title={I18n.t('langageTitre')} >
                    {I18n.t('langue')}
                </a>
            </li>);
        }

        return null;
    }

    render() {
        return (<div id="container" className="container">
            <div id="access-links">
                <a href={I18n.t('urlLogo')} className="sr-only sr-only-focusable" title={I18n.t('contenuPrincipal')}>{I18n.t('contenuPrincipal')}</a>
            </div>
            <header>
                <div id="zoneEntetePage">
                    <div id="zoneEntete" className="zone-entete">
                        <div id="zoneLogo" className="col-xs-6 col-sm-6 col-md-6 col-lg-6 zone-logo">
                            <a id="urlLogo" target="_blank" href={I18n.t('urlLogo')} alt={I18n.t('dsfLogo')}>
                                <img className="img-responsive" id="logoDsf" alt={I18n.t('dsfLogo')} src={I18n.t('dsfCheminImage')} aria-hidden="false"/>
                            </a>
                        </div>
                        <div id="liens" className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                            <ul id='liensHaut' className='pull-right'>
                                {this.afficherLienLangue()}
                            </ul>
                        </div>
                    </div>
                    <div id="secureSection">
                        <div>
                            <span id="labelSectionSecure">{I18n.t('sectionSecurisee')}</span>
                            <span id="logoCadenas" aria-hidden="false">
                                <img id="cadenas" title={I18n.t('sectionSecurisee')} alt={I18n.t('sectionSecurisee')} src={"ic-cadenas.svg"} aria-hidden="false"/>
                            </span>
                        </div>
                    </div>
                </div>
            </header>
        </div>);
    }
}