import React, { Component } from "react";
import I18n from "./I18n";
import ZoneMessages from "./ZoneMessages";

export default class FormulaireCodePaiement extends Component {
    constructor(props) {
        super(props);
        this.state = { value: '', aValider: false, erreur: null, position: 0, applymask: false };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.champInput = React.createRef();
    }

    componentDidUpdate(prevProps) {
       this.champInput.current.setSelectionRange(this.state.position, this.state.position);

        if (this.state.applymask == false) {
            if (this.state.erreur != null || this.props.erreur != null) {
                window.$("#messageErreur").focus();
            }
        }
    }

    componentDidMount() {
        window.$('[data-toggle="popover"]').popover({
            container: "body"
        });
        window.$("label").click(function (e) {
            e.preventDefault();
        });
        window.$('[data-toggle="popover"]').on('show.bs.popover', function() {
            $(this).attr("aria-expanded", "true");
        });
        window.$('[data-toggle="popover"]').on('hide.bs.popover', function() {
            $(this).attr("aria-expanded", "false");
        });
    }

    handleChange(event) {
        var valeur = event.target.value.replace(/\s/g, '').toUpperCase();
        if (valeur.length > 4) {
            if (valeur.length > 9)
                valeur = valeur.slice(0, 4) + " " + valeur.slice(4, 9) + " " + valeur.slice(9);
            else
                valeur = valeur.slice(0, 4) + " " + valeur.slice(4);
        }
        var positionSelect = event.target.selectionStart;
        if (positionSelect === 5 || positionSelect === 11)
            positionSelect = positionSelect + 1;

        this.setState({ value: valeur, position: positionSelect, applymask: true });
        this.validateForm(event.target.value, this.state.aValider);
    }

    handleSubmit(event) {
        this.setState({ aValider: true, applymask: false });
        $('[data-toggle="popover"]').popover('hide');
        var valeur = this.state.value.replace(/\s/g, '');

        if (this.validateForm(valeur, true)) {
            this.props.obtenirGuid && this.props.obtenirGuid(valeur);
        }

        event.preventDefault();
    }

    validateForm(valeur, aValider) {
        if (aValider) {
            if (valeur === "") {
                this.setState({
                    erreur: new EvalError("champVide")
                });

                return false;
            }
            else if (!this.validateNoDemandeFormat(valeur)) {
                this.setState({
                    erreur: new EvalError("formatInvalide")
                });

                return false;
            }
        }

        this.setState({ erreur: null });
        return true;
    }

    validateNoDemandeFormat(valeur) {
        var reg = new RegExp("^[a-zA-Z0-9]*$");
        var valeurtoTest = valeur.replace(/\s/g, '').toUpperCase();

        if (!(valeurtoTest.length === 13 && reg.test(valeurtoTest))) {
            return false;
        }

        return true;
    }

    showErrorStyle() {
        var style = "form-control";
        if (this.state.erreur != null && this.state.erreur instanceof EvalError) {
            style = `${style} has-error `;
        }

        return style;
    }

    showError() {
        if (this.state.erreur != null && this.state.erreur instanceof EvalError) {
            var message = I18n.t("zoneMessages:messagePEC0051");

            if (this.state.erreur.message === "formatInvalide") {
                message = I18n.t("zoneMessages:messagePEC0044");
            }

            return <span data-testid="helpBlock" id="helpBlock" className="has-error">{message}</span>;
        }

        return null
    }

    showSummary() {
        if (this.state.erreur != null) {
            var message = I18n.t("zoneMessages:messagePEC0042");

            if (this.state.erreur.message === "formatInvalide") {
                message = I18n.t("zoneMessages:messagePEC0043");
            }

            return <ZoneMessages id="erreurmsg" typeMessage="erreurValidation" nomChamp="noDemande" message={message} />
        }
        else if (this.props.erreur != null) {
            return <ZoneMessages id="erreurmsg" typeMessage="erreurTechnique" message={this.props.erreur.message} />
        }

        return null;
    }

    render() {
        return (<form id="saisie" onSubmit={this.handleSubmit} noValidate>
            <div id="formulaire" className="form-group form-horizontal">
                {this.showSummary()}
                <label id="libelleNoDemande" htmlFor="noDemande">
                    <span data-testid="noDemande" id="nodemande">{I18n.t('saisieCodePaiement:codePaiement')}</span>&nbsp;
                    <a ref="boutonAide" href="#" id="boutonAide" className="boutonAide" role="button" data-placement="top" data-arrow="center" data-toggle="popover" data-original-title={I18n.t("saisieCodePaiement:titreAide")} data-content={I18n.t("saisieCodePaiement:texteImageAide")} data-html="true" aria-expanded="false">
                        <img className="imageBulleAide" src={"ic-aide.svg"} alt={I18n.t("saisieCodePaiement:titreImageAide")} />
                    </a>
                    {this.showError()}
                    <input data-testid="noDemandeInput" id="noDemande" ref={this.champInput} className={this.showErrorStyle()} aria-descridby="exempleNoDemande" maxLength={15} type="text" value={this.state.value} onChange={this.handleChange} required />
                </label>
                <br aria-hidden="true" />
                <span id="exempleNoDemande">{I18n.t('saisieCodePaiement:codePaiementExemple')}</span>
            </div>

            <input id="submit" className="btn btn-primary btn-bas-formulaire" type="submit" value={I18n.t('saisieCodePaiement:soumettre')} />
        </form>);
    }
}
